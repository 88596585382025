
//674357219370735 fb app id real
//2049822855318927 test app id fb
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";

//import { translate } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import axios from "axios";
import { ErrorMessage, Field, Form, useField, useForm } from "vee-validate";
import store from "@/store";
import i18n from "@/core/plugins/i18n";
import { func } from "@/core/plugins/globalfunc";
import * as Yup from "yup";

declare const window: any;
export default defineComponent({
  name: "customers-listing",
  mixins: [func],
  setup() {
    // Define a validation schema
    const schema = Yup.object({
      password: Yup.string()
        .min(6, "minPassword")
        .max(18, "maxPassword")
        .required("requiredPassword"),
      confirmPassword: Yup.string()
        .min(6, "minPassword")
        .max(18, "maxPassword")
        .required("requiredPassword")
        .oneOf([Yup.ref("password"), null], "matchPassword"),
    });

    // Create a form context with the validation schema
    const { validate } = useForm({
      validationSchema: schema,
    });

    // No need to define rules for fields
    //const username = ref('');
    const { value: password, errorMessage: passwordError } =
      useField<string>("password");
    const { value: confirmPassword, errorMessage: confirmPasswordError } =
      useField<string>("confirmPassword");

    return {
      validate,
      password,
      passwordError,
      confirmPassword,
      confirmPasswordError,
    };
  },
  data() {
    return {};
  },
  mounted() {
    if (store.state.uid) {
      ElMessage.success(this.$t("alreadyLogin"));
    }
    this.password = "";
    this.confirmPassword = "";
  },
  methods: {
    resetPassword() {
      if (store.state.uid) {
        //Show already login when there's UID
        ElMessage.success(this.$t("alreadyLogin"));
      } else {
        this.validate().then((values) => {
          if (values.valid) {
            //Log in backend
            axios
              .post(
                "https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=forgot&action=resetPassword",
                JSON.stringify({
                  uid: this.$route.params.id,
                  code: this.$route.params.code,
                  password: this.password,
                })
              )
              .then((response) => {
                if (response.data === "resetFail") {
                  ElMessage.error(this.$t("resetFail"));
                } else if (response.data === "passwordFail") {
                  ElMessage.error(this.$t("passwordFail"));
                } else if (response.data === "success") {
                  ElMessage.success(this.$t("resetSuccess"));
                  this.$router.push({ name: "login" });
                } else {
                  ElMessage.error(this.$t("resetFail"));
                }
              })
              .catch((e) => {
                ElMessage.error(this.$t("resetFail"));
              });
          }
        });
      }
    },
  },
});
